:root {
    --top-height: 100px;    
    --view-width: 100vw;    
    --view-height: calc(100vh - var(--top-height));
}

@font-face {
    font-family: ostrich-black;
    src: url('/font/ostrich-sans-black.woff');
}

@font-face {
    font-family: ostrich-regular;
    src: url('/font/ostrich-sans-regular.woff');
}

@font-face {
    font-family: ostrich-heavy;
    src: url('/font/OstrichSans-Heavy.otf');
}

@font-face {
    font-family: jura-regular;
    src: url('/font/Jura-Regular.ttf');
}

#view-body {    
    background-image: linear-gradient(160deg, #437FC7, white 50%);
    height: 100%;
    min-height: 100vh;
    max-width: var(--view-width);
}

#background-image-container {
    background-image: url('/images/wave.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto 35%;
    height: 100%;
    min-height: 100vh;
    width: var(--view-width);
}

#layout-top {    
    height: var(--top-height);
    padding: 10px 5vw 10px 5vw;
    width: var(--view-width);    
}

#logo {
    max-height: var(--top-height);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

#layout-content {        
    text-align: center;
    width: 100vw;
}

#about {
    height: 100%;
    margin: 0 !important;
}

#about .column {
    padding: 0 !important;
}

#about h2 {
    color: white;    
    font-family: ostrich-heavy;
    font-size: 3em;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.left {
    text-align: left;
}

#welcome {
    height: 100%;
}

#welcome-message {    
    line-height: 150px;
    font-family: ostrich-regular;
    font-size: 4.5em;
    text-shadow: 2px 2px 4px #474747;
}

#tldr, #contact {
    font-family: jura-regular;
    font-size: 2em;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

#hobbies {
    height: 100%;
}

#hobbies p {
    background-color: #437FC7;
    border-radius: 20px;
    color: white;
    font-family: jura-regular;
    font-size: 1.5em;
    margin-left: auto;
    margin-right: auto; 
    padding: 20px;
    width: 80%;
}

#hobbies h2 {
    margin-top: 10px;
    text-shadow: 2px 2px 4px #474747;
}

#badtzmaru {
    display: inline-block !important;
    margin-right: 10px;
}